import React from 'react';

class Banner extends React.Component {

    constructor(props){
        super(props)
        this.setProperties = this.setProperties.bind(this);
        this.setProperties(props.banner, props.index);
    }

    setProperties(banner, index) {
        this.title = banner.title ? banner.title : '';
        this.image = banner.image ? banner.image : '';
        this.index = index ? index : '';
        this.rating = banner.rating ? banner.rating : '';
        this.caption = banner.caption ? banner.caption : '';
        this.tags = banner.tags ? banner.tags : [];
        this.description = banner.description ? banner.description : '';
    }

    resolveBannerPath(image) {
        return `/assets/images/banners/${image}`;
    }

    render() {
          return (
              <div className="owl-items" key={this.index}>
                  <div className="banner-wrap justify-content-between align-items-center">
                      <div className="full-wrap-slide" style={{backgroundImage: `url(${this.resolveBannerPath(this.image)})`}} />
                  </div>
              </div>
          );
    }
}

export default Banner;