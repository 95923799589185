import React from 'react';
import VideoModel from "../VideoModel";

class Thumb extends React.Component {

    constructor(props) {
        super(props)
        this.setProperties = this.setProperties.bind(this);
        this.showVideoModel = this.showVideoModel.bind(this);
        this.setProperties(props.thumb, props.index);
        this.state = {
            model : false
        }
    }

    setProperties(thumb, index) {
        this.category = thumb.category ? thumb.category : [];
        this.image = thumb.image ? thumb.image : '';
        this.title = thumb.title ? thumb.title : '';
        this.index = index ? index : '';
        this.likes = thumb.likes ? thumb.likes : '';
        this.views = thumb.views ? thumb.views : '';
        this.description = thumb.description ? thumb.description : '';
        this.tags = thumb.tags ? thumb.tags : [];
        this.description = thumb.description ? thumb.description : '';
        this.url = thumb.url ? thumb.url : '';
        this.duration = thumb.duration ? thumb.duration : '';
        this.videoId = thumb.videoId ? thumb.videoId : '';
    }

    resolveThumbPath(image) {
        return `/assets/images/thumbs/${image}`;
    }

    showVideoModel (e) {
        e.preventDefault();
        this.setState({model: true});
    }

    render() {
          return (
              <React.Fragment>
                  <div className="owl-items slide-border" key={this.index}>
                      <div className="owl-items">
                          <a className="slide-one" key={this.index} href={this.url} onClick={(e) => {this.showVideoModel(e)}}>
                              <div className="slide-image">
                                  <img src={this.resolveThumbPath(this.image)} alt={this.title} />
                              </div>
                              <div className="slide-content">
                                   <h2 className="line-clamp">
                                          {this.title}
                                   </h2>
                                  <p className="line-clamp">
                                      {this.description}
                                  </p>
                                  {
                                      this.tags.map((tag, key) => (
                                          <span key={key} className="tag">
                                                  <b>{tag}</b>
                                              </span>
                                      ))
                                  }
                              </div>
                          </a>
                      </div>
                  </div>
                  {this.state.model ? <VideoModel show={true} videoId={this.videoId} /> : ''}
              </React.Fragment>
          );
    }
}

export default Thumb;