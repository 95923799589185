import React from 'react';
import Http from '../components/Http';
import Thumb from "./models/Thumb";

class ThumbList extends Http {

    constructor(props){
        super(props)
        this.heading = props.title;
        this.category = props.category;
        this.listType = props.type ? props.type : '';
        this.thumbList = [];
    }

    componentDidMount() {
        this.get(`/assets/mocks/${this.category}.json`, {});
    }

    processResponse(response) {
        if (response) {
            response.sort(() => Math.random() - 0.5);
            this.thumbList = response.map((thumb, index) =>
                <Thumb thumb={thumb} key={index} />
            );
        }
        document.dispatchEvent(new Event('thumbs-loaded'));
        this.forceUpdate();
    }

    getClassList() {
        if (this.listType && this.listType === 'normal'){
            return 'slide-slider owl-carousel owl-theme slider-small-width';
        } else {
            return 'slide-slider owl-carousel owl-theme slider-small-width'
        }
    }

    render() {
          return (
              <div className="slide-wrapper">
                  <div className="container custom-container-height">
                      <div className="row">
                          <div className="col-sm-6 text-left mb-4 mt-4">
                              <h2>{this.heading}</h2>
                          </div>
                      </div>
                      <div className="row">
                          <div className="col-sm-12">
                              <div className={this.getClassList()}>
                                  {this.thumbList}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          );
    }
}

export default ThumbList;