import React from 'react';

class Faq extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row faq-container">
                        <div className="col-sm-12 text-left">
                            <div id="accordion" className="accordion faq">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="false"
                                                    aria-controls="collapseOne">
                                                What devices can I use to watch TV1 On?
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse" aria-labelledby="headingOne"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            <p>You can enjoy TV1 on a wide variety of devices ranging from Smart TVs, gaming consoles, media players, PC and Mac, tablets and mobile phones.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingTwo">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseTwo" aria-expanded="false"
                                                    aria-controls="collapseTwo">
                                                4K Ultra HD Streaming on TV1
                                            </button>
                                        </h5>
                                    </div>
                                    <div id="collapseTwo" className="collapse" aria-labelledby="headingTwo"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            <p>4K Ultra HD streaming is available for our Premium TV1 subscribers. Provided that you are using a 4K TV1 supported device, the title you have selected is available in 4K Ultra HD and you are on a top-tier connection, expect to see your entertainment of choice in up to 4K Ultra HD.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFour">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseFour" aria-expanded="false"
                                                    aria-controls="collapseFour">
                                                Download your favourite TV Shows and Movies
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseFour" className="collapse" aria-labelledby="headingFour"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Thousands of hours of TV shows and movies from TV1 are available to watch offline, so now you can watch TV1 anytime, anywhere without using up your precious data!
                                                If you're planning on travelling overseas, you can download titles from TV1 to enjoy later for those long flights!
                                                This feature is available on iPhones, iPads & iPod Touches (iOS version 9.3 and above) and Android mobile devices (version 4.4 and above). At this stage, offline download is not available on Windows 10.
                                                Please follow the instructions below to get started.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingFive">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseFive" aria-expanded="false"
                                                    aria-controls="collapseFive">
                                                How will I be billed?
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseFive" className="collapse" aria-labelledby="headingFive"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            <p>TV1 will be billed to your nominated payment method once per month. If you have forgotten your payment method or next billing date, you can easily find it by following these steps. Using a web browser on a Mac or PC, head over to TV1.com.au and log in to your account. Hover your mouse over your profile in the top right corner and select Manage Account</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="card">
                                    <div className="card-header" id="headingSix">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseSix" aria-expanded="false"
                                                    aria-controls="collapseSix">
                                                How do I cancel my TV1 account?
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseSix" className="collapse" aria-labelledby="headingSix"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            <p>TV1 does not have any lock-in contracts, so you are free to cancel anytime. With that said, we would be very sorry to see you go! Before you go ahead and cancel your Stan subscription, TV1 Support would very much like to help you resolve any difficulties you may have experienced. Please feel free to contact us by clicking here and hopefully we can get you back up and running and streaming smoothly.
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Faq;