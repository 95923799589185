import React from 'react';
import about from '../assets/images/all-4.jpg';

class About extends React.Component {
   render() {
      return (
          <React.Fragment>
              <div className="container">
                  <div className="row about-us">
                      <div className="col-sm-8 about-us-text text-justify">
                          <p>
                              <h2>Hi There! Welcome to TV1</h2>
                          </p>
                          <p>
                              <h5>
                                  TV1 is an entertainment and lifestyle global streaming platform offering Australian content. TV1 promotes the most exhilarating events, the most glamorous and successful people, the most in-demand celebrities, the hottest rising stars ﻿, and the most exciting trends down under. TV1 may be new but it’s most certainly in demand and 100% Australian. We are committed to making Aussie local talents, events, and entrepreneurs easily accessible to both our local and international audiences via the highest quality streaming technology.
                              </h5>
                          </p>
                          <p>
                              <h5>
                                  Co-founded by the StarCentral Media Group and the Australian Millionaire Business Network, the TV1 channel is the home for independent Australian Screen content. We very much welcome our local screen practitioners from emerging through to the established to make TV1 their home. By streaming your content on TV1, you join a family of rising stars, entrepreneurs, celebrities, and various artists.
                              </h5>
                          </p>
                          <p>
                              <h5>
                                  <b>MISSION:</b> Our mission is to present the most unique and compelling original Australian content by tapping into the hottest local talents and events.
                              </h5>
                          </p>
                      </div>
                      <div className="col-sm-4 about-us-image d-none d-lg-block">
                            <img src={about} alt="About US" />
                      </div>
                  </div>
              </div>
          </React.Fragment>
      );
   }
}

export default About;