import React from 'react';

class Footer extends React.Component {
   render() {
      return (
          <div className="footer-wrapper">
              <div className="container">
                  <div className="row">
                      <div className="col-sm-12 text-center">
                          <h4 className="mb-4">Join a group of more then <span className="red-text">51,235</span> subscribers and gain access to exclusive contents.</h4>
                      </div>
                      <div className="col-sm-12 text-center newsletter-sub">
                          <form action="/sign-up" className="mt-0">
                              <button value="Submit">Sign Up Now</button>
                          </form>
                      </div>
                      <div className="col-sm-12">
                          <div className="middle-footer">
                              <div className="row">
                                  <div className="col-md-4 col-lg-2 col-sm-6 col-xs-6 md-mb25">
                                      <h5>Social Link</h5>
                                      <ul>
                                          <li><a rel="noreferrer" target="_blank" href="https://www.facebook.com/Tv1australia/">Facebook</a></li>
                                          <li><a rel="noreferrer" target="_blank" href="https://www.instagram.com/tv1australia/">Instagram</a></li>
                                          <li><a rel="noreferrer" target="_blank" href="http://google.com">Instagram</a></li>
                                          <li><a rel="noreferrer" target="_blank" href="https://www.youtube.com/user/StarConnectLive">Youtube</a></li>
                                      </ul>
                                  </div>
                                  <div className="col-md-4 col-lg-2 col-sm-6 col-xs-6 md-mb25">
                                      <h5>ONLINE</h5>
                                      <ul>
                                          <li><a href="/popular">Web</a></li>
                                          <li><a href="/popular">Series</a></li>
                                          <li><a href="/popular">Comedy</a></li>
                                          <li><a href="/popular">Action</a></li>
                                      </ul>
                                  </div>
                                  <div className="col-md-4 col-lg-2 col-sm-6 col-xs-6 md-mb25">
                                      <h5>Language</h5>
                                      <ul>
                                          <li><a href="/popular">English</a></li>
                                          <li><a href="/popular">Spanish</a></li>
                                          <li><a href="/popular">Arab</a></li>
                                          <li><a href="/popular">Urdu</a></li>
                                          <li><a href="/popular">Brazil</a></li>
                                      </ul>
                                  </div>
                                  <div className="col-md-4 col-lg-2 col-sm-6 col-xs-6">
                                      <h5>Channel</h5>
                                      <ul>
                                          <li><a href="/popular">Makeup</a></li>
                                          <li><a href="/popular">Dresses</a></li>
                                          <li><a href="/popular">Girls</a></li>
                                          <li><a href="/popular">Sandals</a></li>
                                          <li><a href="/popular">Headphones</a></li>
                                      </ul>
                                  </div>
                                  <div className="col-md-4 col-lg-2 col-sm-6 col-xs-6">
                                      <h5>About</h5>
                                      <ul>
                                          <li><a href="/faq">FAQ</a></li>
                                          <li><a href="/terms">Term of use</a></li>
                                      </ul>
                                  </div>
                                  <div className="col-md-4 col-lg-2 col-sm-6 col-xs-6">
                                      <h5 className="mb-3">Office</h5>
                                      <p style={{width: "100%"}}>Level 25, <br /> 88 Philip St, <br /> Sydney, <br /> NSW - 2000</p>
                                  </div>
                              </div>
                          </div>
                      </div>
                      <div className="col-sm-12 lower-footer" />
                      <div className="col-sm-6">
                          <p className="copyright-text">&copy;2021 copyright. All rights reserved.</p>
                      </div>
                      <div className="col-sm-6 text-right">
                          <p className="float-right copyright-text">In case of any concern, <a href="/contact">contact us</a></p>
                      </div>
                  </div>
              </div>
          </div>
      );
   }
}

export default Footer;