import React from 'react';
import Banner from './models/Banner';
import Http from '../components/Http';

class BannerList extends Http {

    componentDidMount() {
        this.get('/assets/mocks/banners.json', {});
    }

    processResponse(response) {
        if (response) {
            this.bannerList = response.map((banner, index) =>
                <Banner banner={banner} key={index} />
            );
        }
        document.dispatchEvent(new Event('banners-loaded'));
        this.forceUpdate();
    }

    render() {
          return (
              <div className="banner-wrapper">
                  <div className="container">
                      <div className="row">
                          <div className="col-sm-12">
                              <div className="banner-slider owl-carousel owl-theme">
                                  {this.bannerList}
                              </div>
                          </div>
                      </div>
                  </div>
              </div>
          );
    }
}

export default BannerList;