import React from 'react';
import { Helmet, HelmetProvider } from 'react-helmet-async';
import Header from "./Header";
import BannerList from "./BannerList";
import Footer from "./Footer";
import ReactNotification from 'react-notifications-component'
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import Home from "../pages/Home";
import About from "../pages/About";
import Contact from "../pages/Contact";
import Popular from "../pages/Popular";
import Signup from "../pages/Signup";
import Faq from "../pages/Faq";
import Terms from "../pages/Terms";

class Layout extends React.Component {

    constructor(props){
        super(props)
        this.title = props.title;
    }

   render() {
      return (
          <React.Fragment>
              <Router>
                  <div className="main-wrapper">
                      <HelmetProvider>
                          <Helmet>
                              <title>{this.title}</title>
                          </Helmet>
                      </HelmetProvider>
                      <Header/>
                      <BannerList/>
                      <Switch>
                          <Route exact path="/">
                              <Home />
                          </Route>
                          <Route path="/home">
                              <Home />
                          </Route>
                          <Route path="/popular">
                              <Popular />
                          </Route>
                          <Route path="/about">
                              <About />
                          </Route>
                          <Route path="/contact">
                              <Contact />
                          </Route>
                          <Route path="/sign-up">
                              <Signup />
                          </Route>
                          <Route path="/faq">
                              <Faq />
                          </Route>
                          <Route path="/terms">
                              <Terms />
                          </Route>
                      </Switch>
                      <Footer/>
                      <ReactNotification />
                  </div>
              </Router>
          </React.Fragment>
      );
   }
}

export default Layout;