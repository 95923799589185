import React from 'react';
import axios from 'axios';
import { store } from 'react-notifications-component';

class Http extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            isLoading : false
        }
        this.get = this.get.bind(this);
        this.alert = this.alert.bind(this);
    }

    alert(type, message) {
        store.addNotification({
            title: this.getAlertLabel(type),
            message: message,
            type: type,
            insert: 'top',
            container: 'top-right',
            animationIn: ['animate__animated', 'animate__fadeIn'],
            animationOut: ['animate__animated', 'animate__fadeOut'],
            dismiss: {
                duration: 5000,
                onScreen: true
            }
        });
    }

    getAlertLabel(type) {
        switch(type) {
            case 'danger':
                return 'Error';
            case 'success':
                return 'Success';
            case 'warning':
                return 'Warning';
            case 'info':
                return 'Information';
            default:
                return '';
        }
    }

    get(url, parameters) {
        this.setState({isLoading: true});
        axios.get(url, {params: {parameters}})
       .then(function (response) {
            this.setState({isLoading: false});
            if (response && response.status === 200) {
                this.processResponse(response.data);
            } else {
                this.alert('danger', 'An error occurred while processing your request. Please try again');
            }
       }.bind(this))
       .catch(function (error) {
           this.setState({isLoading: false});
           this.alert('danger', error.message);
       }.bind(this));
    }
}

export default Http;
