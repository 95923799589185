import React from 'react';
import logo from '../assets/images/logo.png';
import avatar from '../assets/images/avatar.png';
import {NavLink} from 'react-router-dom';

class Header extends React.Component {
   render() {
      return (
          <div className="header-wrapper">
              <div className="container-fluid">
                  <div className="row">
                      <div className="col-lg-6 navbar p-0">
                          <NavLink className="logo logo-wrapper" activeClassName="is-active" to="/">
                              <img src={logo} alt="logo" className="dark" />
                          </NavLink>
                          <button className="navbar-toggler" type="button" data-toggle="collapse"
                                  data-target="#navbarNavDropdown" aria-controls="navbarNavDropdown"
                                  aria-expanded="false" aria-label="Toggle navigation">
                              <span className="navbar-toggler-icon" />
                          </button>
                          <div className="collapse navbar-collapse" id="navbarNavDropdown">
                              <ul className="navbar-nav nav-menu float-none text-center">
                                  <li className="nav-item">
                                      <a className="nav-link" href="/">
                                          Home
                                      </a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/popular">
                                          Popular
                                      </a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/about">
                                          About Us
                                      </a>
                                  </li>
                                  <li className="nav-item">
                                      <a className="nav-link" href="/contact">
                                          Contact Us
                                      </a>
                                  </li>
                              </ul>
                          </div>
                      </div>
                      <div className="col-lg-2 subscriber-button d-none d-sm-none d-md-none d-lg-block d-xl-block d-xxl-block">
                          <a href="/sign-up">Join 51,235 Subscribers</a>
                      </div>
                      <div className="col-lg-4 search-container">
                          <div className="user-avater">
                              <img src={avatar} alt="user avatar" />
                              <div className="user-menu">
                                  <ul>
                                      <li><a href="http://google.com"><i className="ti-user" />My Profile</a></li>
                                      <li><a href="http://google.com"><i className="ti-heart" />My Favorites</a></li>
                                      <li><a href="http://google.com"><i className="ti-world" />Privacy Policy</a></li>
                                      <li><a href="http://google.com"><i className="ti-power-off" />Log Out</a></li>
                                  </ul>
                              </div>
                          </div>
                          <div className="search-div">
                              <input type="text" placeholder="Seacrh" />
                          </div>
                      </div>
                  </div>
              </div>
          </div>
      );
   }
}

export default Header;