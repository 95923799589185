import React from 'react';

class Signup extends React.Component {
   render() {
      return (
          <React.Fragment>
              <div className="container">
                  <div className="row signup-container">
                      <div className="col-sm-12 contact-us-form text-left">
                          <form>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                                  <input type="text" className="form-control" id="name"
                                         aria-describedby="Name" />
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                  <input type="email" className="form-control" id="exampleInputEmail1"
                                         aria-describedby="emailHelp" />
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputPassword1" className="form-label">Contact Number</label>
                                  <input type="password" className="form-control" id="exampleInputPassword1" />
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputPassword1" className="form-label">Password</label>
                                  <input type="password" className="form-control" id="exampleInputPassword1" />
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputPassword1" className="form-label">Confirm Password</label>
                                  <input type="password" className="form-control" id="exampleInputPassword1" />
                              </div>
                              <div className="mb-3 form-check">
                                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                      <label className="form-check-label" htmlFor="exampleCheck1">I agree terms and conditions listed on the <a href="/terms">terms and conditions page</a></label>
                              </div>
                              <button type="submit" className="btn btn-primary">Sign Up</button>
                          </form>
                      </div>
                  </div>
              </div>
          </React.Fragment>
      );
   }
}

export default Signup;