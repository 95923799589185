import React from 'react';
import 'reactjs-popup/dist/index.css';
import Popup from 'reactjs-popup';

class videoModel extends React.Component {

    constructor (props) {
        super(props);
        this.state = {
            open: props.show ? props.show : false
        }
        this.closeTrigger = props.closeTrigger;
        this.videoId = props.videoId ? props.videoId : '';
        this.closePopup = this.closePopup.bind(this);
        this.getVideoUrl = this.getVideoUrl.bind(this);
    }

    closePopup () {
        this.setState({open: false});
    }

    getVideoUrl () {
        return `//www.youtube.com/embed/${this.videoId}?autoplay=1`;
    }

    render() {
        return (
            <React.Fragment>
                <Popup open={this.state.open} closeOnDocumentClick  onClose={() => {this.closePopup()}}>
                    {close => (
                        <div className="video-container">
                            <iframe title="video frame" width="460" height="230"
                                    src={this.getVideoUrl()}
                                    frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen="1" tabIndex="-1" />
                            <button
                                className="close"
                                onClick={() => {
                                    this.closePopup();
                                    close();
                                }}
                            />
                        </div>
                    )}
                </Popup>
            </React.Fragment>
        );
    }
}

export default videoModel;