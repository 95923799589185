import React from 'react';
import ThumbList from '../components/ThumbList';

class Popular extends React.Component {
   render() {
      return (
          <React.Fragment>
              <ThumbList title='Popular Videos' category='popular' type='normal' />
          </React.Fragment>
      );
   }
}

export default Popular;