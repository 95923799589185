import React from 'react';

class Featured extends React.Component {

    constructor(props){
        super(props)
        this.videoId = props.videoId;
    }

    getVideoUrl () {
        return `//www.youtube.com/embed/${this.videoId}`;
    }

    render() {
          return (
              <div className="container">
                  <div className="row">
                      <div className="col-sm-12 text-left featured-container-title">
                          <h2>Now Playing on TV1 Australia</h2>
                      </div>
                  </div>
                  <div className="featured-container">
                      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/DaUUGPm77vQ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
                  </div>
              </div>
          );
    }
}

export default Featured;