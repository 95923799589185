import React from 'react';
import ThumbList from '../components/ThumbList';
import Featured from '../components/Featured';

class Home extends React.Component {
   render() {
      return (
          <React.Fragment>
              <Featured videoId='BhXrpYk_45c' />
              <ThumbList title='FILMCENTRAL TV' category='filmcentral' />
              <ThumbList title='MS TV' category='mstv' />
              <ThumbList title='StarCentral TV' category='starcentral' />
              <ThumbList title='Millionaires Alliance TV' category='million' />
              <ThumbList title='Sassy & Co TV' category='sassy' />
              <ThumbList title='Golden Sash TV' category='gstv' />
              <ThumbList title='Special Interests' category='special' />
              <ThumbList title='Music' category='music' />
          </React.Fragment>
      );
   }
}

export default Home;