import React from 'react';
import ReactDOM from 'react-dom';
import './assets/scss/main.scss';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'owl.carousel/dist/owl.carousel.min';
import 'react-notifications-component/dist/theme.css';
import 'animate.css/animate.min.css';
import Layout from "./components/Layout";

ReactDOM.render(
  <React.StrictMode>
    <Layout />
  </React.StrictMode>,
  document.getElementById('root')
);
