import React from 'react';

class Terms extends React.Component {
    render() {
        return (
            <React.Fragment>
                <div className="container">
                    <div className="row terms-container">
                        <div className="col-sm-12 text-left">
                            <div id="accordion" className="accordion terms">
                                <div className="card">
                                    <div className="card-header" id="headingOne">
                                        <h5 className="mb-0">
                                            <button className="btn btn-link collapsed" data-toggle="collapse"
                                                    data-target="#collapseOne" aria-expanded="false"
                                                    aria-controls="collapseOne">
                                                Terms and Conditions
                                            </button>
                                        </h5>
                                    </div>

                                    <div id="collapseOne" className="collapse show" aria-labelledby="headingOne"
                                         data-parent="#accordion">
                                        <div className="card-body">
                                            <p>Persons under 18 years of age must read these blogs accompanied by an
                                                adult. Some of these blogs may contain occasional violence, swearing and
                                                coarse language as well as sexually suggestive language. If you don’t have
                                                a sense of humor, kindly go away and have a fabulous day!</p>
                                            <p className="MsoNormal"
                                               style={{margin: "10px 10px 10px 0pt", textAlign: "justify"}}>By using or
                                                accessing this segment of our website, you agree to be bound by these
                                                Terms of Service. Please read the terms of this Agreement carefully. If
                                                you do not agree to the terms of this Agreement, then you may not use or
                                                access this segment of the website.</p>
                                            <p className="MsoNormal"
                                               style={{margin: "10px 10px 10px 0pt", textAlign: "justify"}}>StarCentral
                                                Magazine may, in its sole discretion, modify this Agreement from time to
                                                time, and may modify, suspend, or terminate the Segment and Services, for
                                                any reason, and without notice. TV1 recommends that you
                                                review these terms from time to time to note any changes. By continuing to
                                                use or access the Site or any of the Services, you agree to be bound by
                                                the Terms of Service in effect at the time of such use or access.</p>
                                            <p className="MsoNormal"
                                               style={{margin: "10px 10px 10px 0pt", textAlign: "justify"}}>StarCentral
                                                Magazine does not claim any ownership rights in any of the photos or
                                                artistic works that are posted on this blog site. StarCentral does not
                                                endorse or accept any responsibility for the contents and opinions
                                                expressed of this blog site. It is up to the user to take precautions to
                                                ensure that any downloads or any materials are free of viruses or other
                                                items of a destructive nature. Under no circumstances will StarCentral
                                                Magazine be responsible for any direct, indirect or other consequential
                                                damages, including any lost profits, business interruptions or lost
                                                programmes or data on your system, through any use of this web site or any
                                                other linked site or any damages how so ever caused by content of this
                                                blog site.</p>
                                            <p className="MsoNormal"
                                               style={{margin: "10px 10px 10px 0pt", textAlign: "justify"}}>This blog site may
                                                also contain published rumours, speculation, assumptions, opinions as well
                                                as factual information. Information on this blog site may or may not be
                                                true and not meant to be taken as fact. TV1 makes no
                                                warranty as to the validity of any claims.</p>
                                            <p className="MsoNormal"
                                               style={{margin: "10px 10px 10px 0pt", textAlign: "justify"}}>When you enter any
                                                competition on the site, you understand and agree that the results are
                                                FINAL and BINDING. You also understand and agree that&nbsp;there might
                                                come a time when the circumstances would be beyond our control therefore
                                                TV1 and its partners should not&nbsp;be
                                                blamed&nbsp;for&nbsp;any errors in the final result especially if the end
                                                result of the given competition is not favourable to many.</p>
                                            <p className="MsoNormal"
                                               style={{margin: "10px 10px 10px 0pt", textAlign: "justify"}}>StarCentral
                                                Magazine also respects the right to privacy of every individual who visits
                                                our site. This Privacy Policy explains how we use any personal information
                                                we collect about you.</p>
                                            <p>What personal information do we collect?</p>
                                            <p>
                                                <ul>
                                                    <li>When you contact us using the “Contact Us” form on the website or make
                                                        a comment on one of our blog posts&nbsp;you understand and agree that
                                                        we collect your name and&nbsp;email address so that we&nbsp;can keep
                                                        you informed of any news and offers from TV1.
                                                    </li>
                                                    <li>When you enter any competition, promotion or prize draw on the site,
                                                        we may collect your name, address, email address and telephone number.
                                                        This information is used for the purpose of entering you in the
                                                        competition, promotion or prize draw and for the purposes explained in
                                                        the terms and conditions applicable to the competition, promotion or
                                                        prize draw.
                                                    </li>
                                                    <li>If you agree, we will also collect your name, address, email address
                                                        and telephone number so that other companies&nbsp;that are a partner
                                                        of TV1&nbsp;can keep you informed of their news and
                                                        offers.
                                                    </li>
                                                    <li>When you visit the site, we log the IP address of the machine you use
                                                        to access our site. The IP address is a unique number that is used on
                                                        the Internet to identify a unique computer for the purposes of
                                                        allowing computers to communicate with each other.
                                                    </li>
                                                    <li>We will also disclose any of your personal information to any third
                                                        party where we are under a duty to do so in order to comply with any
                                                        legal obligation, or in order to enforce or protect any of our rights,
                                                        property or safety (or those of our customers).
                                                    </li>
                                                    <li>TV1 is a participant in the Amazon Services LLC
                                                        Associates Program, an affiliate advertising program designed to
                                                        provide a means for sites to earn advertising fees by advertising and
                                                        linking to tv1.com (amazon.com).
                                                    </li>
                                                </ul>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

export default Terms;