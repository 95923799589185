import React from 'react';

class Contact extends React.Component {
   render() {
      return (
          <React.Fragment>
              <div className="container">
                  <div className="row contact-us">
                      <div className="col-sm-8 contact-us-form text-justify">
                          <form>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">Name</label>
                                  <input type="text" className="form-control" id="name"
                                         aria-describedby="Name" />
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputEmail1" className="form-label">Email address</label>
                                  <input type="email" className="form-control" id="exampleInputEmail1"
                                         aria-describedby="emailHelp" />
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputPassword1" className="form-label">Contact Number</label>
                                  <input type="password" className="form-control" id="exampleInputPassword1" />
                              </div>
                              <div className="mb-3">
                                  <label htmlFor="exampleInputPassword1" className="form-label">Message</label>
                                  <textarea  rows="3" className="form-control" id="exampleInputPassword1" />
                              </div>
                              <div className="mb-3 form-check">
                                  <input type="checkbox" className="form-check-input" id="exampleCheck1" />
                                      <label className="form-check-label" htmlFor="exampleCheck1">I agree terms and conditions listed on the <a href="/terms">terms and conditions page</a></label>
                              </div>
                              <button type="submit" className="btn btn-primary">Submit</button>
                          </form>
                      </div>
                      <div className="col-sm-4 contact-us-map d-none d-lg-block">
                          <div className="map-container">
                              <iframe title="contact-us" width="100%" height="100%" id="gmap_canvas"
                                      src="https://maps.google.com/maps?q=Level%2025,%2088%20Philip%20St,%20Sydney,%20NSW%20-%202000&t=&z=13&ie=UTF8&iwloc=&output=embed"
                                      frameBorder="0" scrolling="no" marginHeight="0" marginWidth="0" />
                          </div>
                      </div>
                  </div>
              </div>
          </React.Fragment>
      );
   }
}

export default Contact;